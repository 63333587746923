import './Slider.css';
import React from 'react';
import { Carousel, Button, Image, Spinner } from 'react-bootstrap/';
import slider1 from "../images/slider1.jpg";
import slider2 from "../images/slider2.jpg";
import slider3 from "../images/slider3.jpg";
import slider3_new from "../images/slider3_new.png";


function Slider() {

  const handleClickCareer = () => {
    var career = document.getElementById('career');
    if (career) {
      career.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickProjects = () => {
    var projects = document.getElementById('projects');
    if (projects) {
      projects.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickAboutMe = () => {
    var aboutme = document.getElementById('aboutme');
    if (aboutme) {
      aboutme.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ display: 'block', maxWidth: "100vw", width: "100%", height: "100%", overflow: "hidden" }}>
      <Carousel>
        <Carousel.Item interval={2500}>
          <Image
            className="d-block w-100"
            src={slider1}
            alt="Image One"
          />
          <Carousel.Caption style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant="outline-light" style={{ border: "none", fontSize: "2rem", padding: "20px" }} onClick={handleClickCareer}>
              <Spinner style={{ marginRight: "5px" }} animation='grow' />
              <b>Career</b>
            </Button>
          </Carousel.Caption>
          <Carousel.Caption>
            <b style={{ marginTop: "100px" }}>1</b>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2500}>
          <Image
            className="d-block w-100"
            src={slider2}
            alt="Image Two"
          />
          <Carousel.Caption style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant="outline-light" style={{ border: "none", fontSize: "2rem", padding: "20px" }} onClick={handleClickProjects}>
              <Spinner style={{ marginRight: "5px" }} animation='grow' />
              <b>Projects</b>
            </Button>
          </Carousel.Caption>
          <Carousel.Caption>
            <b style={{ marginTop: "100px" }}>2</b>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2500}>
          <Image
            className="d-block w-100"
            src={slider3_new}
            alt="Image Three"
          />
          <Carousel.Caption style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant="outline-light" style={{ border: "none", fontSize: "2rem", padding: "20px" }} onClick={handleClickAboutMe}>
              <Spinner style={{ marginRight: "5px" }} animation='grow' />
              <b>About Me</b>
            </Button>
          </Carousel.Caption>
          <Carousel.Caption>
            <b style={{ marginTop: "100px" }}>3</b>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Slider;
