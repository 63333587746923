import './ProfileCard.css';
import React from 'react';
import { Container, Row, Col, Card, Badge, Image } from 'react-bootstrap/';
import Ata from '../images/ata2.jpg';
import LinkedIn from '../icons/linkedin.png';
import GitHub from '../icons/github.png';
import Instagram from '../icons/instagram.png';

function ProfileCard() {
  return (
    <Container style={{ marginTop: "50px" }} id='aboutme'>
      <h3><b>👤 About Me</b></h3>
      <div className='divider'/>
      <Row>
        <Col xs={12} md={3}>
          <Card className='my-3' style={{ width: '100%', marginTop: "30px" }}>
            <Card.Img variant="top" src={Ata} />
            <Card.Body>
              <Card.Title>Mustafa Ata Onbas</Card.Title>
              <Card.Text>
                MSc. Business Informatics student at TU Wien
              </Card.Text>
              <div className="d-flex justify-content-around mt-3">
                <Badge bg="light" text="dark">
                  <a href='https://www.linkedin.com/in/aonbas/'>
                    <Image src={LinkedIn} style={{ width: "30px" }}/>
                  </a>
                </Badge>
                <Badge bg="light" text="dark">
                  <a href='https://github.com/mustafaataonbas'>
                    <Image src={GitHub} style={{ width: "30px" }}/>
                  </a>
                </Badge>
                <Badge bg="light" text="dark">
                  <a href='https://www.instagram.com/mataonbas/'>
                    <Image src={Instagram} style={{ width: "30px" }}/>
                  </a>
                </Badge>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={9}>
          <Card className='my-3' style={{ width: '100%', marginTop: "30px" }}>
            <Card.Body>
              <Card.Text>Hi there! 👋</Card.Text>
              <Card.Text>
                I'm an aspiring computer science and engineering graduate who loves designing apps and learning new perspectives. 
                I have technical skills, particularly in C++, React.js, Java, Python, CSS, and Adobe Photoshop, and an academic background in mathematics, and engineering. 
              </Card.Text>
              <Card.Text>
              • MSc. Business Informatics student at TU Wien
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileCard;
