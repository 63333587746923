import './Career.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Career() {
  return (
    <Container style={{ marginTop: "50px" }} id='career'>
      <h3><b>💼 Career</b></h3>
      <div className='divider'></div>
      <Row>
        <Col>
          <Card className='my-3' style={{ width: '100%' }}>
            <Card.Body>
              <Card.Title>➡️ <b>VeriPark</b></Card.Title>
              <Card.Text>June 2024 - October 2024 ● Istanbul, Turkey</Card.Text>
              <Card.Text><b>- Software Developer</b></Card.Text>
              <Card.Text>November 2023 - June 2024 ● Istanbul, Turkey</Card.Text>
              <Card.Text><b>- Associate Software Developer</b></Card.Text>
              <Card.Text>
                • Carried out developments by using Angular.js, NetCore & Entity Framework. 
                I took part in different screen developments in the field of banking and 
                finance, such as mortgage, credit card application, term life 
                insurance and online loan application screens.
              </Card.Text>
            </Card.Body>
            <div className='divider'></div>
            <Card.Body>
              <Card.Title>➡️ <b>Yapı Kredi Technology</b></Card.Title>
              <Card.Text>March 2022 - November 2023 ● Istanbul, Turkey</Card.Text>
              <Card.Text>
                <b>Part-Time Internship in Data Warehouse and Business Intelligence Team – (Volunteer & Compulsory Internship) </b>
              </Card.Text>
              <Card.Text>
                • Designed and developed web applications with the Datawarehouse and Business Intelligence team, 
                also on my own, by using React JS and Flask. I took part in application developments for internal use. 
                These applications check the values of the website where the end-of-day report is located 
                properly on the dashboard by using image processing coded with Python. Not only that, 
                I also took part in web application development using React and Flask. In other words, 
                I participated in R&D studies and application development as we know it.
              </Card.Text>
            </Card.Body>
            <div className='divider'></div>
            <Card.Body>
              <Card.Title>➡️ <b>Şekerbank T.A.Ş</b></Card.Title>
              <Card.Text>February - March 2022 ● Istanbul, Turkey</Card.Text>
              <Card.Text><b>Internship in the Software Architecture Team - (Volunteer Internship)</b></Card.Text>
              <Card.Text>
                • Developed an API analyzer with Java Springboot to derive various of information about a given API such as HTTP method counts, HTTP methods list.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Career;
