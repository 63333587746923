import './Projects.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Projects() {
  return (
    <Container style={{ marginTop: "50px" }} id='projects'>
      <h3><b>📜 Projects</b></h3>
      <div className='divider'></div>
      <Row>
        <Col>
          <Card className='my-3' style={{ width: '100%' }}>
            <Card.Body>
              <Card.Title>➡️ Code Clone Detection Analyzer</Card.Title>
              <Card.Text>
                • We developed an integrated framework to manage code clones, a persistent challenge in software
                engineering that often leads to code redundancy and quality deterioration. Our framework combines
                three distinct code clone detection tools: SimpleCC, Duplicate Code Detection, and our unique tool,
                myCC. Utilizing the bigCloneBench dataset for testing, the framework allows flexible detection and
                management of code clones as well as reducing system complexity and maintenance effort.
              </Card.Text>

              <Card.Title>➡️ Import & Export Tables to Database</Card.Title>
              <Card.Text>
                • I have developed a web app that can upload Excel files as a data frame into the PLSQL database. Also,
                you can download data frames as an Excel file by writing a SQL script that can derive the table from
                PLSQL and turn it into a .csv file. This app has been planned to use for these operations I have
                mentioned previously in a more collective manner that combines both import & export from PLSQL.
              </Card.Text>

              <Card.Title>➡️ Serious Game for Cerebral Palsy Patients</Card.Title>
              <Card.Text>
                • I have developed a serious game named SeriousRacing on Unity that can be played via a Wii balance
                board. I have used blender community designs while developing the game components. Meanwhile, the
                whole user interface and scripts that connect them were designed and developed by me. There is also an
                integrated backend that would also make physiotherapists sign in and sign up for the serious game. Also,
                this has been developed via node.js.
              </Card.Text>

              <Card.Title>➡️ Segmentation Simulation Project:</Card.Title>
              <Card.Text>
                • I had a chance to be a part of the development team. I have developed mainly the front-end of this
                application with React.js, and at the same time, I had a part in back-end which was developed with
                Django. The aim of the segmentation simulation as its name suggest is to categorize customer types that
                are reside in Yapı Kredi Bank with a specific filtering algorithm.
              </Card.Text>

              <Card.Title>➡️ PMO Estimator Project</Card.Title>
              <Card.Text>
                • Designed a web application with a team by using React JS and Flask. The application is used for cost 
                estimation solutions inside the company.
              </Card.Text>

              <Card.Title>➡️ API Assistant Development</Card.Title>
              <Card.Text>
                • Developed an API analyzer with Java Springboot to derive various of information about a given API
                such as HTTP method counts, HTTP methods list. APIs are analyzed and stored with the use of linked
                list.
              </Card.Text>

              <Card.Title>➡️ Mobile Application Development (CS310) – Pet Project (Raspy)</Card.Title>
              <Card.Text>
                • Developed a mobile application for pets and their owners to meet with other pets and owners. The project
                conducted as a group. Flutter was used while working on this project.
              </Card.Text>

              <Card.Title>➡️ Introduction to Data science (CS210) - IMDb Film Rating Estimator Project</Card.Title>
              <Card.Text>
                • Worked on the IMDb score database with a team to understand how the vote number, meta score and
                year affect IMDb film ratings. While working on this project various technics were used such as
                utilization of data, exploratory data analysis, hypothesis testing and machine learning.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Projects;
