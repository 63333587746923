import React from "react";
import ProfileCard from '../components/ProfileCard';
import LowerBar from "../components/LowerBar";
import Slider from "../components/Slider";
import Divider from "../components/Divider";
import Career from "../components/Career";
import Projects from "../components/Projects";

export default class MainMenuPage extends React.Component {
    render(){
        return(
            <React.StrictMode>
                <div>
                    <Slider/>
                    <Divider/>
                    <Career/>
                    <Projects/>
                    <ProfileCard/>
                    <LowerBar/>
                </div>
            </React.StrictMode>
        )
    }
}