import { Placeholder } from 'react-bootstrap';
import './Divider.css';
import {React} from 'react';


function Divider() {
  return (
    <Placeholder.Button xs={12} style={{borderRadius:"0px"}} />
  );
}

export default Divider;