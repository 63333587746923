import './LowerBar.css';
import {React} from 'react';
import Navbar from 'react-bootstrap/Navbar';

function UpperBar() {
  return (
    <div>
      <Navbar data-bs-theme="dark" className="bg-body-tertiary" style={{height:"40px", bottom:"-100px", marginTop:"120px"}} >
          <Navbar.Brand style={{fontSize:15, marginLeft:"15px"}}>Version 2024.07</Navbar.Brand>
      </Navbar>
    </div>
  );
}

export default UpperBar;
