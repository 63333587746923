import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import MainMenuPage from './pages/MainMenuPage';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <Router>
      <Routes>
      <Route exact path='/' element={<MainMenuPage/>} />
      </Routes>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
